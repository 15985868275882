import React, { FunctionComponent } from "react";
import Image from "gatsby-image";
import { PostSnippet } from "../../types";

export interface FeaturePosts {
  featurePosts: PostSnippet[];
}

export const FeaturePost: FunctionComponent<PostSnippet> = ({
  title,
  summary,
  href,
  img,
  imgAlt,
  tags,
}) => {
  return (
    <div className="flex flex-col border-black border-2 font-display rounded-lg shadow-card overflow-hidden">
      <div className="flex-shrink-0">
        <a href={href}>
          <Image
            fluid={img}
            alt={imgAlt || title}
            className="h-48 w-full object-cover"
          />
        </a>
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="leading-5 font-medium text-orange-400">
            {tags.map((tag, index) => (
              <span key={index}>
                <a href={`/tags/${tag}`} className="hover:underline">
                  #{tag}
                </a>{" "}
              </span>
            ))}
          </p>
          <a href={href} className="block">
            <h3 className="mt-2 text-3xl leading-7 font-semibold text-gray-900">
              {title}
            </h3>
            {/* <p className="mt-3 text-base leading-6 text-gray-500">{summary}</p> */}
          </a>
        </div>
      </div>
    </div>
  );
};

export const TopFeaturePost: FunctionComponent<PostSnippet> = ({
  title,
  summary,
  href,
  img,
  imgAlt,
}) => {
  return (
    <div>
      <div class="h-144 p-14 w-full bg-orange-400 font-display text-white">
        <h1 class="text-6xl">a website about products, development, <br/>& probably some other stuff.</h1>
        <h2 class="text-3xl">We're not Scrum certified nor do we have our Sigma Black belt. <br/>But we're obsessed with software, the internet and a litany of dumb shit.</h2>
        <form name="subscribe" method="POST" data-netlify="true">
          <p class="text-3xl mt-8">
            <label>Give us your email if you want this in your inbox: <input type="email" name="email" class="text-3xl bg-orange-400 border-b-4	border-white mr-8" /></label>            
            <button class="text-xl bg-white text-black p-2 rounded-lg shadow-card hover:text-white hover:bg-black" type="submit">Subscribe</button>

          </p>
         
          <p>
          </p>
        </form>
      </div>
       
      {/* <a href={href}>
        <Image
          fluid={img}
          alt={imgAlt || title}
          className="h-144 w-full object-cover rounded"
        />
        <h1 className="text-4xl text-center my-3">{title}</h1>
        <div className="mb-16 max-w-prose mx-auto text-center text-lg text-gray-600">
          {summary}
        </div>
      </a> */}
    </div>
  );
};

export const FeaturePosts: FunctionComponent<FeaturePosts> = ({
  featurePosts,
}) => {
  const [topPost, ...otherFeature] = featurePosts;
  return (
    <div className="relative py-6">
      <div className="relative max-w-7xl mx-auto">
        <TopFeaturePost {...topPost} />
        <h2 className="pt-4 text-5xl leading-9 tracking-tight font-display text-gray-900 sm:leading-10 text-left mt-3 mb-8">
          Good stories
        </h2>
        <div className="mt-3 grid gap-5 xl:gap-10 max-w-lg mx-auto grid-cols-1 md:grid-cols-3 md:max-w-none">
          {otherFeature.map((featurePost, index) => (
            <FeaturePost {...featurePost} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
